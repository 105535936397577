<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-reporte-liquidaciones"
      style="background-color: #00000082"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Consolidado Turnos TIF</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Año</label>
                      <select
                        class="form-control form-control-sm col-md-12"
                        v-model="filtros.año"
                        :class="
                          $v.filtros.año.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Mes</label>
                      <select
                        class="form-control form-control-sm col-md-12"
                        v-model="filtros.mes"
                        :class="
                          $v.filtros.mes.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="mes in listasForms.meses"
                          :key="mes.cod_alterno"
                          :value="mes.cod_alterno"
                        >
                          {{ mes.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">Liquidación</label>
                    <input
                      type="number"
                      id="id"
                      v-model="filtros.id"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresas"
                      label="razon_social"
                      :options="listasForms.empresas"
                      @input="getSelectEmpresa()"
                      class="col-md-7"
                      multiple
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Bloque</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="bloque"
                      placeholder="Bloques"
                      label="nombre"
                      class="col-md-7"
                      :options="listasForms.bloques"
                      @input="getSelectBloque()"
                      multiple
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Producto</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.producto"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="producto in listasForms.tipoProducto"
                        :key="producto.id"
                        :value="producto.id"
                      >
                        {{ producto.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="transportadora_id" class="col-md-5"
                      >Tipo tarifa</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_tarifa"
                      :class="
                        $v.filtros.tipo_tarifa.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_tarifa in listasForms.tipoTarifa"
                        :key="tipo_tarifa.numeracion"
                        :value="tipo_tarifa.numeracion"
                      >
                        {{ tipo_tarifa.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can('tif.reportes.exportLiquidaciones') &&
                  !$v.filtros.$invalid
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "TifReporteLiquidaciones",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      bloque: [],
      empresa: [],
      filtros: {
        id: null,
        placa: null,
        remolque: null,
        mes: null,
        año: null,
        empresa: null,
        bloque: null,
      },
      listasForms: {
        productos: [],
        empresas: [],
        placas: [],
        tipoTurno: [],
        tipoTarifa: [],
        tipoVehículo: [],
        bloques: [],
        tifOperacion: [],
        tifRuta: [],
        tipoProducto: [],
        meses: [],
      },
    };
  },
  validations: {
    filtros: {
      mes: {
        required,
      },
      año: {
        required,
      },
      tipo_tarifa: {
        required,
      },
    },
  },
  methods: {
    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipoTarifa = response.data;
      });
    },

    getTipoOperacion() {
      axios.get("/api/lista/120").then((response) => {
        this.listasForms.tifOperacion = response.data;
      });
    },

    getMes() {
      axios.get("/api/lista/152").then((response) => {
        this.listasForms.meses = response.data;
      });
    },

    getTipoTurno() {
      let me = this;
      var url = "api/admin/turnos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipoTurno = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEmpresas() {
      let me = this;
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.empresas = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getPlacas() {
      let me = this;
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.placas = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoVehículo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipoVehículo = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getBloque() {
      let me = this;
      var url = "api/admin/bloques/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.bloques = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoProducto() {
      let me = this;
      var url = "api/admin/productos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipoProducto = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoRuta() {
      let me = this;
      var url = "/api/tif/rutas/lista";
      axios
        .get(url)
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tifRuta = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSelectEmpresa() {
      this.filtros.empresa = null;
      if (this.empresa) {
        this.filtros.empresa = this.empresa.id;
      }
    },

    getSelectPlaca() {
      this.filtros.placa = null;
      if (this.placa) {
        this.filtros.placa = this.placa.id;
      }
    },

    getSelectBloque() {
      this.filtros.bloque = null;
      if (this.bloque) {
        this.filtros.bloque = this.bloque.id;
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tif/turnos/tifReporteLiquidaciones",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    limpiarModal() {
      this.filtros = {
        id: null,
        placa: null,
        remolque: null,
        mes: null,
        año: null,
        empresa: null,
        bloque: null,
      };
    },
  },

  mounted() {
    this.getTipoTarifa();
    this.getMes();
    this.getTipoTurno();
    this.getTipoVehículo();
    this.getBloque();
    this.getTipoRuta();
    this.getTipoProducto();
    this.getTipoOperacion();
    this.getEmpresas();
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
